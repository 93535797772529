import React, { useRef } from "react";
import AboutUs from "../componentsxx/AboutUsSection";
import { quickLinksType } from "../componentsxx/Footer";
import { scrollToComponent } from "../componentsxx/LinkElement";
import { navMenuDataT, navMenuOpt, NavMenuPropsT } from "../componentsxx/NavMenu";
import Reviews from "../componentsxx/Reviews";
import Services from "../componentsxx/Services";
import { cardData, cardDataT } from "../componentsxx/ServicesData";
import TopSection from "../componentsxx/TopSection";
import { textObj } from "../text/text";
import Layout from "../componentsxx/Layout";
import { pageStyles } from "../styles/componentStyles";
import ContactForm from "../componentsxx/ContactForm";
import { SEO } from "../componentsxx/SEO";
import { Map, MapDefaultLoc } from "../componentsxx/Map";

const { navMenu, titleMain, descriptionMain } = textObj;

//adapted from https://projects.thebusinesslogos.com/wp/Tanner-Acree/

//used in layout and landingPage
export const sectionPadding = 50;

const index = () => {
  //generate map of ComponentRef vs Text
  //Header Button:
  const contactUsRef = useRef(null); //init refs
  const contactUsOnClick = () => scrollToComponent(contactUsRef);
  const headerButton: navMenuDataT["headerButton"] = {
    headerText: textObj.navMenu.headerButton.headerText,
    onClick: contactUsOnClick,
    ref: contactUsRef,
  };

  //Non-Header Buttons:
  const homeRef = useRef(null);
  const homeOnClick = () => scrollToComponent(homeRef);
  const servicesRef = useRef(null);
  const servicesOnClick = () => scrollToComponent(servicesRef);
  const testimonialsRef = useRef(null);
  const testimonialOnClick = () => scrollToComponent(testimonialsRef);
  const aboutUsRef = useRef(null);
  const aboutUsOnClick = () => scrollToComponent(aboutUsRef);

  //load all menu options text data
  // warn: intentionally using Type Def with optional onClick() func property
  // so we may override .onClick
  const menuOptsWithOnClick: navMenuDataT["menuOptions"] = navMenu.menuOptions;
  //WARNING: setting onClick events to TEXT data structure
  //         if the TEXT shifts in order, then these onClick indices should be updated!
  menuOptsWithOnClick[0].onClick = homeOnClick;
  // asserting text file contains services at index 1
  const servicesTxtIdx = 1;
  menuOptsWithOnClick[servicesTxtIdx].onClick = servicesOnClick;
  //2 Common Equipment: has no section to go to
  //3 Portfolio:        has no section to go to
  menuOptsWithOnClick[4].onClick = testimonialOnClick;
  menuOptsWithOnClick[5].onClick = aboutUsOnClick;

  //services nested suboptions links to go to specific elements:
  // warn: if subindices at index don't exist > runtime crash, so make sure text has those elements
  const servicesSubOpts: navMenuDataT["menuOptions"] =
    textObj.navMenu.menuOptions[servicesTxtIdx]?.subOptions || [];
  //for every service: create Ref, assign onClick at Index
  //append these onClicks to the Services > CardData section
  servicesSubOpts.forEach((_, idx) => {
    const servicesSubRef = useRef(null);
    servicesSubOpts[idx].onClick = () => scrollToComponent(servicesSubRef);
    //assigning refs based on index of each card, consumed by <Services/>
    servicesSubOpts[idx].ref = servicesSubRef;
  });
  //append these onClicks to the Services > CardData section
  // Merge card data Images with Text
  const cardDataWithRefs: cardDataT[] = servicesSubOpts.map((el, idx) => {
    const cardDataAtIdx = cardData[idx];
    return {
      ...el,
      ...cardDataAtIdx,
    };
  });

  //build the quick links in the footer, relative to the Nav menu clicks
  // future? if we want to read in external urls from text file
  // const quickLinks = textObj.footer.quickLinks;
  //selecting a subset of indices from menu options with pre-defined onClick+refs for auto-scrolling
  const quickLinkIdxs = [0, servicesTxtIdx, 4, 5];
  const footerData: quickLinksType[] = menuOptsWithOnClick.filter((_, idx) => {
    return quickLinkIdxs.includes(idx);
  });

  return (
    <main style={pageStyles}>
      <Layout
        homeRef={homeRef}
        headerButton={headerButton}
        menuOptions={menuOptsWithOnClick}
        footerData={footerData}
        contactUsRef={contactUsRef}
      >
        <TopSection
          //scroll down to services section
          viewOurWorkClick={servicesOnClick}
        />
        <Services servicesRef={servicesRef} cardData={cardDataWithRefs} />

        <Reviews testimonialsRef={testimonialsRef} />

        <AboutUs aboutUsRef={aboutUsRef} />

        <ContactForm />

        <Map srcStr={MapDefaultLoc} />
      </Layout>
    </main>
  );
};

export default index;

export const Head = () => <SEO title={titleMain} description={descriptionMain} />;
