import { Button } from "@mui/material";
import { Col, Row } from "antd";
import React from "react";
import { textStyle } from "../styles/componentStyles";
import { textObj } from "../text/text";
import TopSectionImg from "./TopSectionImg";

const { headerText, subText } = textObj.topSection;

//verified works to a minimum screen width of 320px
//<TopSectionImg/> must have minHeight: "450px" (at least)

const TopSection = (props: { viewOurWorkClick: () => void }) => {
  const { viewOurWorkClick } = props;
  return (
    <>
      <Col
        span={24}
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "relative",
          //backgroundColor: "pink",
          display: "block",
        }}
      >
        <div
          style={{
            height: "100%",
            //backgroundColor: "green",
          }}
        >
          <TopSectionImg />
        </div>

        <div
          style={{
            flex: 1,
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            //backgroundColor: "pink",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            //zIndex: "2",
            //create a 'gray haze effect' over the image
            background:
              "radial-gradient(circle at top left,rgba(34,34,34,0.5) 0%,rgba(31,62,78,0.6) 100%)",
          }}
        >
          <Row
            style={{
              width: "90%",
              justifyContent: "center",
              textAlign: "center",
              animation: "fadeIn 5s",
              paddingTop: 20,
            }}
          >
            <p
              //warn: class fadeInText + fadeInText2 are text animations in styles.css
              className="fadeInText"
              style={{
                ...textStyle,
                textOverflow: "ellipsis",
                fontSize: 36,
                letterSpacing: "3px",
                lineHeight: "1em",
              }}
            >
              {headerText}
            </p>
          </Row>
          <Row
            style={{
              width: "75%",
              //backgroundColor: "pink",
              textAlign: "center",
              justifyContent: "center",
              //paddingTop: 20,
            }}
          >
            <h1
              className="fadeInText2"
              style={{
                ...textStyle,
                fontSize: 18,
                textOverflow: "ellipsis",
                fontStyle: "italic",
                lineHeight: "1.6em",
                textAlign: "center",
              }}
            >
              {subText}
            </h1>
          </Row>

          <Row
            style={{
              paddingTop: 20,
            }}
          >
            <div style={{ height: 40 }} />
            <Button
              variant="outlined"
              sx={{
                color: "white",
                borderColor: "white",
                borderRadius: 0,
                letterSpacing: "4px",
                fontSize: "14px",
              }}
              onClick={viewOurWorkClick}
            >
              View Our Work
            </Button>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default TopSection;
