import BoomLift from "./ServiceImages/BoomLift";
import CementTruck from "./ServiceImages/CementTruck";
import Excavator1 from "./ServiceImages/Excavator1";
import Excavator2 from "./ServiceImages/Excavator2";
import TractorAndSkidSteerer from "./ServiceImages/TractorAndSkidSteerer";
import { textObj } from "../text/text";
import { refType } from "./CommonTypes";

const { cardDataText } = textObj.servicesSection;

const cardImageData = [
  {
    //excavation
    ImageProp: Excavator2,
    backgroundColor: "red",
  },
  {
    //demolition
    ImageProp: Excavator1,
    backgroundColor: "red",
  },
  {
    //land clearing
    ImageProp: TractorAndSkidSteerer,
    backgroundColor: "red",
  },

  {
    //tree removal
    ImageProp: BoomLift,
    backgroundColor: "red",
  },
  {
    //cement
    ImageProp: CementTruck,
    backgroundColor: "red",
  },
];

export type cardDataT = {
  ImageProp: () => JSX.Element;
  backgroundColor: string;
  headerText: string;
  descriptionText: string;
  buttonText: string;
  buttonLink: string;
  ref?: refType;
};

//merge text file with images
export const cardData: cardDataT[] = cardDataText.map((textData, textDataIdx) => {
  return {
    ...textData,
    //caution index may get out-of-bounds
    ...cardImageData[cardImageData.length > textDataIdx ? textDataIdx : 0],
  };
});
