import { Button } from "@mui/material";
import { Col, Row } from "antd";
import React, { CSSProperties, useState } from "react";
import { COLORS } from "../text/colors";
import { emailHref } from "../text/constants";
import { textObj } from "../text/text";
import ContactBanner from "./ContactBanner";

const { textColorHeaders, textColorReviewMain } = COLORS.review;

const textStyle = {
  color: textColorHeaders,
  fontSize: 13,
  lineHeight: 2,
};

const textContainerStyle: CSSProperties = {
  margin: 0,
  padding: 0,
  border: 0,
  wordBreak: "break-word",
  textAlign: "center",
  letterSpacing: 0,
};

const { contactHeaderText, contactSubheaderText } = textObj.contactForm;

//WARN: Most of this was stolen from TESTIMONIALS SECTION
//TODO: Condense the styling > reduce code
const ContactForm = () => {
  return (
    <div
      style={{
        paddingTop: 64,
        paddingBottom: 64,
        //paddingLeft: 70,
        //paddingRight: 70,
        backgroundColor: "#000000",
      }}
    >
      <Row justify="center" style={{ paddingBottom: 5 }}>
        <Col>
          <p
            //Contact Us
            style={{
              ...textContainerStyle,
              ...textStyle,
              fontSize: 38,
              letterSpacing: "0px",
            }}
          >
            {contactHeaderText}
          </p>
        </Col>
      </Row>

      <Row
        justify="center"
        style={
          {
            //paddingBottom: 5,
          }
        }
      >
        <Col span={24}>
          <ContactBanner textColor="white" />
        </Col>
      </Row>

      <Row
        justify="center"
        style={
          {
            //paddingBottom: 5,
          }
        }
      >
        <Col
          //PHONE NUMBER && EMAIL ADDRESS
          span={24}
        >
          <p style={{ ...textContainerStyle, ...textStyle }}>{contactSubheaderText}</p>
        </Col>
      </Row>

      <div
        style={{
          padding: 20,
          //paddingLeft: 40, //warn may fuck up row/col resizing
          //paddingRight: 40, //warn may fuck up row/col resizing
          //backgroundColor: "pink",
        }}
      >
        <ContactInputs />
      </div>
    </div>
  );
};

const ContactInputs = () => {
  const textStyleLocal = { ...textContainerStyle, ...textStyle, width: 320 };
  const inputStyleLocal = { ...textStyleLocal, color: "black" };
  const rowInputStyle = { paddingBottom: 15 };

  const [stateEmail, setEmail] = useState("");
  const [stateSubject, setSubject] = useState("");
  const [stateMessage, setMessage] = useState("");

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const onSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };
  //ChangeEventHandler<HTMLTextAreaElement>'
  const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };
  //MouseEvent<HTMLAnchorElement, MouseEvent>
  //        onClick?: MouseEventHandler<T> | undefined;

  const handleClear = (): void => {
    setEmail("");
    setSubject("");
    setMessage("");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); //needed?
    //console.log("handleSubmit: ", event);

    //extract data and put into request
    //should start: "mailto: name@gmail.com";
    let mailToVar = `${emailHref}`;
    mailToVar += `?`; //begin appending params
    mailToVar += `&subject=${stateSubject}`;
    //MUST encodeURI or newlines are not respected.. strange world we live in
    mailToVar += `&body=${encodeURI(stateMessage)}`;

    //example working string:
    //const mailToVar = `mailto:email@example.com?cc=secondemail@example.com, anotheremail@example.com, &bcc=lastemail@example.com&subject=Mail from our Website&body=Some body text here`;

    //execute an example mailto
    // opens client default email
    window.location.href = mailToVar;
  };

  return (
    <Row justify="center" style={{ paddingTop: 20 }}>
      <form
        onSubmit={handleSubmit}
        //warn: do not attempt to use 'action' + 'mailto:..' here
        // method (get or post) + action{mailToVar}
        // will not work as expected: Nested spaces in body get screwed up
        // hence handle onSubmit and extract data from React.state
      >
        {/*you dont need a name...
        <label style={textStyleLocal}>
          <Row>Name</Row>
          <Row style={rowInputStyle}>
            <input style={inputStyleLocal} type="text" name="name" id="name" />
          </Row>
        </label>
        */}

        <label style={textStyleLocal}>
          <Row>Email</Row>
          <Row style={rowInputStyle}>
            <input
              style={inputStyleLocal}
              type="email"
              name="email"
              id="email"
              required={true}
              value={stateEmail}
              onChange={onEmailChange}
            />
          </Row>
        </label>

        <label style={textStyleLocal}>
          <Row>Subject</Row>
          <Row style={rowInputStyle}>
            <input
              style={inputStyleLocal}
              type="text"
              name="subject"
              id="subject"
              value={stateSubject}
              onChange={onSubjectChange}
            />
          </Row>
        </label>

        <label style={textStyleLocal}>
          <Row>Message</Row>
          <Row style={rowInputStyle}>
            <textarea
              style={inputStyleLocal}
              name="body"
              id="body"
              value={stateMessage}
              onChange={onMessageChange}
              rows={5}
            />
          </Row>
        </label>

        <Row style={{ paddingTop: 20 }}>
          <Col span={12}>
            <Row justify="center" style={rowInputStyle}>
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "white",
                  borderRadius: 0,
                  letterSpacing: "4px",
                  fontSize: "14px",
                }}
                type="reset"
                onClick={handleClear}
              >
                Clear
              </Button>

              {/*
              <input type="reset" value="Clear" />
              */}
            </Row>
          </Col>
          <Col span={12}>
            <Row justify="center" style={rowInputStyle}>
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "white",
                  borderRadius: 0,
                  letterSpacing: "4px",
                  fontSize: "14px",
                }}
                type="submit"
              >
                Send
              </Button>
            </Row>
          </Col>
        </Row>
      </form>
    </Row>
  );
};

export default ContactForm;
