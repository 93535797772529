import React, { CSSProperties } from "react";

//WARN: duplicated
const textContainerStyle: CSSProperties = {
  margin: 0,
  //padding: 0,
  //border: 0,
  wordBreak: "break-word",
  textAlign: "center",
  letterSpacing: 0,
};

export const HeaderText = ({ headerText }: { headerText: string }) => {
  return (
    <p
      //Excavation
      style={{
        ...textContainerStyle,
        color: "black",
        lineHeight: 2,
        fontSize: 23,
        letterSpacing: "0px",
        //color: "white",
      }}
    >
      {headerText}
    </p>
  );
};
