import React from "react";

//To generate: google Maps > type in location
// for start/end points: hit directions and enter start/end points
//Menu button (top left) > share or embed map > embed a map
//197 Old Swede Road Douglassville PA
export const MapDefaultLoc =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3044.5903969247934!2d-75.7271172843146!3d40.26263347292026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c67d7aa4eff901%3A0xd056e592d3ac4850!2s197%20Old%20Swede%20Rd%2C%20Douglassville%2C%20PA%2019518!5e0!3m2!1sen!2sus!4v1680542449620!5m2!1sen!2sus";

type inMapT = {
  srcStr?: string;
};

export const Map = ({ srcStr }: inMapT) => {
  //if no srcString provided, we do not display map
  //example: 'interior-demolition' page
  if (!srcStr || srcStr?.length < 1) return null;

  return (
    <div
      style={{
        paddingBottom: "50%",
        position: "relative",
      }}
    >
      <iframe
        //src for directions:
        //src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d48688.011362882135!2d-75.72205142035159!3d40.29794318410961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x89c67d7aa4eff901%3A0xd056e592d3ac4850!2s197%20Old%20Swede%20Rd%2C%20Douglassville%2C%20PA%2019518!3m2!1d40.2626294!2d-75.7249286!4m5!1s0x89c6817188a90349%3A0xca0a8eb1b40156b2!2sBoyertown%2C%20Pennsylvania%2019512!3m2!1d40.333707499999996!2d-75.63740829999999!5e0!3m2!1sen!2sus!4v1680541940916!5m2!1sen!2sus"
        //src for single point:
        //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3044.5903969247934!2d-75.7271172843146!3d40.26263347292026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c67d7aa4eff901%3A0xd056e592d3ac4850!2s197%20Old%20Swede%20Rd%2C%20Douglassville%2C%20PA%2019518!5e0!3m2!1sen!2sus!4v1680542449620!5m2!1sen!2sus"
        src={srcStr}
        style={{
          //height: 450,
          //width: 600,
          border: 0,
          height: "100%",
          width: "100%",
          left: 0,
          top: 0,
          position: "absolute",
        }}
        allowFullScreen={false}
        loading={"lazy"}
        referrerPolicy={"no-referrer-when-downgrade"}
      ></iframe>
    </div>
  );
};
