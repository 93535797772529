import React, { CSSProperties } from "react";
import { textObj } from "../text/text";
import { COLORS } from "../text/colors";
import { Carousel, Col, Row } from "antd";
import { ReviewImgColleenQ, ReviewImgDefault, ReviewImgGreenBeanie } from "./ReviewImg";
import QuoteImg from "./QuoteImg";
import { Rating } from "@mui/material";
import { refType } from "./CommonTypes";

const {
  textColorHeaders,
  textColorReviewMain,
  textColorReviewDetails,
  starColor,
  thumbtackReviewsLinkColor,
} = COLORS.review;

const textStyle = {
  color: textColorHeaders,
  fontSize: 13,
  lineHeight: 2,
};

const textStyleReview = {
  ...textStyle,
  color: textColorReviewMain,
  lineHeight: "19px",
};

const textContainerStyle: CSSProperties = {
  margin: 0,
  padding: 0,
  border: 0,
  wordBreak: "break-word",
  textAlign: "center",
  letterSpacing: 0,
};

const {
  reviews,
  reveiewsHeaderText,
  reviewsHeaderSubText1,
  reviewsHeaderSubText2,
  allReviewsURL,
  allReviewsLinkText,
} = textObj.reviewSection;

type reviewDataT = {
  reviewText: string;
  author: string;
  jobType: string;
  jobDetails: string;
  date: string;
  stars: number;
};

const reviewElement = (reviewData: reviewDataT, tileIdx: number) => {
  const { author, reviewText, jobDetails, jobType, date, stars } = reviewData;
  return (
    <div
      style={
        {
          //backgroundColor: "yellow",
        }
      }
    >
      <Col
        style={{
          backgroundColor: "white",
          borderRadius: 20,
          paddingTop: 25,
          paddingBottom: 25,
          paddingLeft: 20,
          paddingRight: 20,
          margin: 10,
        }}
      >
        <p style={{ ...textStyleReview }}>{reviewText}</p>

        <p
          style={{
            ...textStyleReview,
            color: textColorReviewDetails,
            fontSize: 12,
          }}
        >
          {jobType}
        </p>

        <p
          style={{
            ...textStyleReview,
            color: textColorReviewDetails,
          }}
        >
          {jobDetails}
        </p>

        <Row
          //1 user image + 1 container (of: Quote, Name, Stars)
          //justify="center"
          style={
            {
              //backgroundColor: "green"
            }
          }
        >
          <Col
            span={10}
            style={
              {
                //backgroundColor: "blue"
              }
            }
          >
            {/*Load a specific image*/}
            {tileIdx === 0 && <ReviewImgDefault />}
            {tileIdx === 1 && <ReviewImgGreenBeanie />}
            {tileIdx === 2 && <ReviewImgColleenQ />}
          </Col>
          <Col
            span={14}
            style={
              {
                //backgroundColor: "green"
              }
            }
          >
            <Row
            //row of 3 components: Quote Image, Name, Stars
            // align="middle"
            >
              <Col
                span={3}
                style={
                  {
                    //backgroundColor: "yellow"
                  }
                }
              >
                <Col
                  //Intentionally empty spacer
                  span={2}
                  style={
                    {
                      //backgroundColor: "pink"
                    }
                  }
                >
                  <p></p>
                </Col>
                <Col
                  span={22}
                  style={
                    {
                      //backgroundColor: "pink"
                    }
                  }
                >
                  <QuoteImg />
                </Col>
              </Col>

              <Col
                span={1}
                style={
                  {
                    //backgroundColor: "yellow",
                  }
                }
              ></Col>

              <Col
                span={20}
                style={{
                  //backgroundColor: "red",
                  minWidth: 105,
                }}
              >
                <Col
                  //Intentionally empty spacer
                  span={2}
                  style={
                    {
                      //backgroundColor: "pink"
                    }
                  }
                >
                  <p></p>
                </Col>
                <Col
                  span={24}
                  style={
                    {
                      //backgroundColor: "purple"
                    }
                  }
                >
                  <p style={{ ...textStyleReview, margin: 0 }}>
                    <b>{author}</b>
                  </p>
                </Col>

                <Col
                  //5 star images
                  span={24}
                  style={
                    {
                      //backgroundColor: "purple",
                    }
                  }
                >
                  <Row
                  //*ALT: use antd icons: import { StarFilled } from "@ant-design/icons";
                  >
                    <Rating
                      name="half-rating-read"
                      sx={{ color: starColor }}
                      defaultValue={stars}
                      precision={0.5}
                      readOnly
                    />
                  </Row>
                </Col>

                <Col>
                  <p
                    style={{
                      ...textStyleReview,
                      color: textColorReviewDetails,
                    }}
                  >
                    {date}
                  </p>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

const Reviews = (props: { testimonialsRef: refType }) => {
  const { testimonialsRef } = props;

  return (
    <div
      ref={testimonialsRef}
      style={{
        paddingTop: 64,
        paddingBottom: 64,
        //paddingLeft: 70,
        //paddingRight: 70,
        backgroundColor: "#000000",
      }}
    >
      <Row justify="center" style={{ paddingBottom: 5 }}>
        <Col>
          <p
            //REVIEWS
            style={{
              ...textContainerStyle,
              ...textStyle,
              fontSize: 23,
              letterSpacing: "3px",
            }}
          >
            {reveiewsHeaderText}
          </p>
        </Col>
      </Row>

      <Row justify="center" style={{ paddingBottom: 5 }}>
        <Col>
          <p
            //What Our Clients Say
            style={{
              ...textContainerStyle,
              ...textStyle,
              fontSize: 38,
              letterSpacing: "0px",
            }}
          >
            {reviewsHeaderSubText1}
          </p>
        </Col>
      </Row>

      <Row
        justify="center"
        style={
          {
            //paddingBottom: 5,
          }
        }
      >
        <Col
          //xs={12} sm={12} md={12} lg={12}

          //WARN: matches Review center elements
          xs={10}
          sm={9}
          md={22}
          lg={24}
        >
          <p style={{ ...textContainerStyle, ...textStyle }}>{reviewsHeaderSubText2}</p>
        </Col>
      </Row>

      <div
        style={{
          padding: 20,
          //paddingLeft: 40, //warn may fuck up row/col resizing
          //paddingRight: 40, //warn may fuck up row/col resizing
          //backgroundColor: "pink",
        }}
      >
        <Row
          //gutter = add spacing between column elements
          //[horizontal, vertical]
          gutter={[20, 20]}
          style={
            {
              //paddingTop: 50, paddingBottom: 50,
              //backgroundColor: "orange",
            }
          }
        >
          {/*get review data from text, append list as elements*/}
          {reviews.map((el, tileIdx) => ReviewTile(el, tileIdx))}
        </Row>

        <Row justify="center" style={{ paddingTop: 20 }}>
          <Col>
            <a
              style={{
                //textJustify: "center",
                // ...aStyle,
                //block: make it match parent height/width
                //so on hover it turns to black
                display: "block",
                color: thumbtackReviewsLinkColor,
              }}
              href={allReviewsURL}
            >
              {allReviewsLinkText}
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ReviewTile = (reviewData: reviewDataT, tileIdx: number) => {
  return (
    <Col
      //container for Reviews
      xs={24}
      sm={24}
      md={12}
      lg={8}
      style={
        {
          //backgroundColor: "red"
        }
      }
    >
      <Row
        //rows of reviewElement() per width:
        //small: 1, med: 2, large: 3 (8*3=24)
        style={{
          justifyContent: "center",
        }}
      >
        <Col
          xs={21}
          sm={21}
          md={21}
          lg={21}
          style={
            {
              // backgroundColor: "red"
            }
          }
        >
          {reviewElement(reviewData, tileIdx)}
        </Col>
      </Row>
    </Col>
  );
};

export default Reviews;
