import { Button } from "@mui/material";
import { Col, Row } from "antd";
import React from "react";
import { textObj } from "../text/text";
import { refType } from "./CommonTypes";
import { DescriptionText } from "./DescriptionText";
import SectionHeaderContainer, { SectionHeaderPTag } from "./SectionTitle";
import { Link } from "gatsby";
import { pathGen } from "../Utils";

const { headerText, subText } = textObj.aboutUsSection.stub;

const AboutUs = (props: { aboutUsRef: refType }) => {
  const { aboutUsRef } = props;

  return (
    <>
      <Row
        ref={aboutUsRef}
        justify="center"
        style={{
          paddingTop: 64,
          paddingBottom: 64,
          paddingLeft: 64,
          paddingRight: 64,
        }}
      >
        <Col span={24}>
          <SectionHeaderContainer>
            <SectionHeaderPTag headerText={headerText} />
          </SectionHeaderContainer>
        </Col>

        <Col span={24}>
          {/*<HeaderText headerText={headerText} />*/}
          <DescriptionText descriptionText={subText} />
        </Col>

        <Row
          //unclear why row needed, but only way to get button centered
          style={{
            paddingTop: 20,
          }}
        >
          <Col
            span={24}
            style={{
              color: "black",

              borderColor: "black",
              borderRadius: 0,
              letterSpacing: "4px",
              fontSize: "14px",
            }}
          >
            <Link to={`${pathGen("/about-us/")}`}>
              <Button
                variant="outlined"
                sx={{
                  color: "black",
                  borderColor: "black",
                  borderRadius: 0,
                  letterSpacing: "4px",
                  fontSize: "14px",
                }}
              >
                About Us
              </Button>
            </Link>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default AboutUs;
