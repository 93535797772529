import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Excavator2 = () => {
  return (
    <StaticImage
      src="../../images/services/Excavator2.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      transformOptions={{
        fit: "cover",
      }}
      style={{
        //height needed for extra small screen width Col={xs}
        height: "100%",
        //height: "100%",
        //minHeight: servicesImageHeight / 2,
        //minHeight: minHeight,
        backgroundColor: "white",
      }}
    />
  );
};

export default Excavator2;
