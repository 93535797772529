import { Button } from "@mui/material";
import { Col, Row } from "antd";
import { Link } from "gatsby";
import React from "react";
import { textObj } from "../text/text";
import { refType } from "./CommonTypes";
import { DescriptionText } from "./DescriptionText";
import { HeaderText } from "./HeaderText";
import SectionHeaderContainer, { SectionHeaderPTag } from "./SectionTitle";
import { cardDataT } from "./ServicesData";
import { pathGen } from "../Utils";

//symbols vs screen widths:
//xs: < 576px, sm: ≥ 576px, md: ≥ 768px, lg: ≥ 992px, xl: ≥ 1200px, xxl: ≥ 1600px
//WARN: WIDE IMAGES WILL NOT LOOK GOOD!
//example: had to take Excavator1 image (original: wide 1980x1200 and convert to square 1080x1080 > looks much better!)
const XS_IMAGE_WIDTH = 300;

const { headerText } = textObj.servicesSection;

const colCountWidthSmall = 1;
const colCountWidthMed = 2;
const colCountWidthLarge = 3;

//This card has some things to know about it
//1: the Outter Column decides: How many columns to show: xs={24 / colCountWidthSmall} (1,2 or 3)
//2: there is 1 ROW that is present, ever.
//3: the ROW that displays is either:
//   A: 2 columns, 1 vertical image to left, and 1 vertical area of text to right
//      *only when windows with is NOT xs: xs={0}
//   B: 1 Column: 1 image vertically on TOP, and 1 vertical text area BELOW
//   *only when windows with IS GREATER THAN width xs: xs={24}, sm={0}
//So as you start from very THIN screen:
//Image Above, Text Below.
//As you get Wider:
//Image Left, Text Right
//AND Number of Columns increase (due to outter most <Col> xs={24 / colCountWidthSmall})

const ButtonToPage = (props: { buttonLink: string; buttonText: string }) => {
  const { buttonLink, buttonText } = props;
  return (
    <Link to={`${pathGen(buttonLink)}`}>
      <Button
        variant="outlined"
        sx={{
          color: "black",
          borderColor: "black",
          borderRadius: 0,
          letterSpacing: "4px",
          fontSize: "14px",
        }}
      >
        {buttonText}
      </Button>
    </Link>
  );
};

const Card = ({ props }: { props: cardDataT }) => {
  const { ImageProp, headerText, descriptionText, backgroundColor, ref, buttonText, buttonLink } =
    props;
  return (
    <Col
      ref={ref}
      xs={24 / colCountWidthSmall}
      sm={24 / colCountWidthSmall}
      md={24 / colCountWidthMed}
      lg={24 / colCountWidthMed}
      xl={24 / colCountWidthMed}
      style={
        {
          //backgroundColor: backgroundColor,
        }
      }
    >
      <Row
        style={{
          //////height: servicesImageHeight, //TODO: WE WANT THIS when not on XS width
          boxShadow:
            "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
        }}
      >
        {/*BEGIN Image(LEFT)/Text(RIGHT) Medium Width+ View*/}
        <Col
          //if the window gets tooo small, remove image
          //else it is always 1/2 the row
          xs={0}
          sm={0}
          md={0}
          lg={12}
          xl={12}
        >
          <div
            style={{
              height: "100%",
              // backgroundColor: "red",
            }}
          >
            <ImageProp />
            {/*Warn attempted to add overlay gradient, and did not look good*/}
          </div>
        </Col>
        <Col
          //if the window gets tooo small, remove text column
          //else it is always 1/2 the row
          xs={0}
          sm={0}
          md={0}
          lg={12}
          xl={12}
        >
          <div
            style={
              {
                //height: servicesImageHeight / 2,
                //backgroundColor: "blue",
              }
            }
          >
            <Row
              style={{
                textAlign: "left",
                padding: 20,
                paddingTop: 10,
              }}
            >
              <Col
                span={24}
                style={{
                  paddingBottom: 10,
                }}
              >
                <HeaderText headerText={headerText} />
              </Col>
              <Col span={24}>
                <DescriptionText descriptionText={descriptionText} />
              </Col>
            </Row>

            <Row justify="center" style={{ paddingBottom: 20 }}>
              <Col>
                <ButtonToPage buttonLink={buttonLink} buttonText={buttonText} />
              </Col>
            </Row>
          </div>
          {/*end column right*/}
        </Col>
        {/*END Image(LEFT)/Text(RIGHT) Medium Width+ View*/}

        {/*BEGIN Image(above)Text(below) Small Width or LESS View*/}
        <Col
          //if the window gets tooo small, then only THIS column exists
          //else this column does not exist
          xs={24}
          sm={24}
          md={24}
          lg={0}
          xl={0}
          style={{
            height: "100%",
            //backgroundColor: "red",
          }}
        >
          <div style={{}}>
            <div
              style={{
                //image container
                height: XS_IMAGE_WIDTH,
                //backgroundColor: "pink",
              }}
            >
              <ImageProp />
            </div>

            {/*TEXT CONTAINERS  */}
            <div
              style={{
                padding: 20,
              }}
            >
              <div
                //text container1
                style={{
                  //backgroundColor: "blue",
                  paddingBottom: 15,
                }}
              >
                <HeaderText headerText={headerText} />
              </div>

              <div
                //text container2
                style={
                  {
                    //backgroundColor: "yellow",
                  }
                }
              >
                <DescriptionText descriptionText={descriptionText} />
              </div>

              <Row justify="center">
                <ButtonToPage buttonLink={buttonLink} buttonText={buttonText} />
              </Row>
            </div>
          </div>
        </Col>
        {/*End Image(above)Text(below) Small Width or LESS View*/}
      </Row>
    </Col>
  );
};

const Services = (props: { servicesRef: refType; cardData: cardDataT[] }) => {
  const { cardData = [], servicesRef } = props;
  return (
    <div
      ref={servicesRef}
      style={{
        paddingTop: 64,
        paddingBottom: 64,
        // backgroundColor: "green",
      }}
    >
      <SectionHeaderContainer>
        <SectionHeaderPTag headerText={headerText} />
      </SectionHeaderContainer>

      <Row
        //Warn: do not style nested Row > Col, may not make gutter work
        //      instead create Row > Col > Div and only style nested div, then gutter works
        //gutter is just adding a [horizontal/vertical] MARGIN between each element
        //gutter = add spacing between column elements
        //[horizontal, vertical]
        gutter={[60, 30]}
        //gutter={[{ xs: 30, sm: 100, md: 50, lg: 0 }, 30]}

        style={{
          //warn: gutter > horizontal will attempt to
          //pad outside the parent (causes entire website width to expand),
          //prevent this with width: 100%
          width: "100%",
          margin: 0,
          paddingTop: 40,
          paddingBottom: 40,
          //backgroundColor: "green",
        }}
      >
        {cardData.map((el, idx) => {
          return <Card key={idx} props={el} />;
        })}
      </Row>
    </div>
  );
};

export default Services;
