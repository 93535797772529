import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const TopSectionImg = () => {
  return (
    <StaticImage
      //to create 'zoom-In/Out' effect:
      //set: height, style > maxHeight, layout: fullWidth, transformOptions > fit: contain
      src="../images/houselandscaping.jpeg"
      alt="logo"
      placeholder="blurred" //while loading show blur
      layout="fullWidth"
      transformOptions={{
        fit: "contain",
      }}
      //height={200}
      //style maxHeight enables image to expand width,
      style={{
        maxHeight: "calc(50vh - 4rem)",
        //warn minHeight impacts screen widths < 320px
        //we need enough height for all the text on top of the image
        minHeight: "450px",
        background:
          "radial-gradient(circle at top left,rgba(34,34,34,0.5) 0%,rgba(31,62,78,0.6) 100%)",
      }}
    />
  );
};

export default TopSectionImg;
