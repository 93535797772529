import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const ReviewImgDefault = () => {
  return (
    <StaticImage
      src={"../images/reviews/reviewExampleUser.png"}
      alt="reviewImage"
      placeholder="blurred" //while loading show blur
      layout="constrained"
      width={87}
      height={87}
    />
  );
};

export const ReviewImgColleenQ = () => {
  return (
    <StaticImage
      src={"../images/reviews/reviewColleenQ.webp"}
      alt="reviewImage"
      placeholder="blurred" //while loading show blur
      layout="constrained"
      width={87}
      height={87}
      style={{
        borderRadius: "50%",
      }}
    />
  );
};
export const ReviewImgGreenBeanie = () => {
  return (
    <StaticImage
      src={"../images/reviews/girl-green-beanie.jpg"}
      alt="reviewImage"
      placeholder="blurred" //while loading show blur
      layout="constrained"
      width={87}
      height={87}
      style={{
        borderRadius: "50%",
      }}
    />
  );
};
